import React from "react";

function Praktijkvoorbeeld({one, two, three}){
    return (
<div className='voorbeeld'>
            <p>{one}</p>
        <br/><br/>
            <p>{two}</p>
            <br/><br/>
            <p>{three}</p>
            <br/><br/>
</div>
    )
}

export default Praktijkvoorbeeld;